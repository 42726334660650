<template>
  <div class="page">
    <div class="content">
        <div style="width: 100%;height: 80vh;display: flex;justify-content: space-between;">
        <div class="left">
            <div class="left-text">基础组件</div>
            <div class="box">
                <div @click="menuClick(1)" class="menu_box">
                    <img style="margin-right: 10px" src="@/assets/homeset/banner.png"/>
                    <div>轮播广告</div>
                </div>
                <div @click="menuClick(2)" class="menu_box">
                    <img style="margin-right: 10px" src="@/assets/homeset/grid.png"/>
                    <div>宫格导航</div>
                </div>
                <div @click="menuClick(3)" class="menu_box">
                    <img style="margin-right: 10px" src="@/assets/homeset/image.png"/>
                    <div>图片广告</div>
                </div>
                <div @click="menuClick(4)" class="menu_box">
                    <img style="margin-right: 10px" src="@/assets/homeset/video.png"/>
                    <div>视频</div>
                </div>
            </div>
        </div>
        <div class="between">
            <!--  -->
            <div class="app">
                <draggable
                    v-model="formData"
                    chosen-class="chosen"
                    force-fallback="true"
                    group="people"
                    animation="500"
                    :handle="'.dragg'"
                    @start="onStart"
                    @end="onEnd"
                    >
                    <transition-group>
                        <template>
                            <div v-for="(items, index) in formData" :key="index + 'a'" @click.stop.prevent="clickActive(items, index)">
                                <div :class="active_id === index ? 'items-active' : 'items'">
                                    <!-- 删除 -->
                                    <img class="del_icon" @click.stop.prevent="delBtn(index)" src="@/assets/homeset/del.png"/>
                                    <!-- items.type == 1  -->
                                    <div v-if="items.type == 1"  :style="`padding:${items.content.paddingData.top}px ${items.content.paddingData.leftRight}px ${items.content.paddingData.bottom}px;`">
                                        <div :style="`height:${items.height}px`"  class="swiper-container dragg">
                                            <div class="swiper-wrapper">
                                                <div v-for="(item, i) of items.content.imgList" :key="i" class="swiper-slide">
                                                    <img :style="`width: 100%;height: 100%;object-fit: cover;border-radius:${items.content.paddingData.borderRadius}px`"
                                                        :src=" item.picview"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="swiper-pagination"></div>
                                    </div>
                                    <!-- items.type == 2 宫格导航 -->
                                    <div v-if="items.type == 2"  :style="`background:${items.content.bgColor};`">
                                        <div class="grid-box dragg" >
                                            <div v-for="(i, j) of items.content.iconList" :key="j" class="items-box">
                                                <img :style="`width:${items.content.width}px;height:${items.content.height}px`" :src="i.picview"/>
                                                <div :style="`font-size:${items.content.size}px;color:${items.content.textColor}`">
                                                    {{ i.title }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--items.type == 3 图片广告 -->
                                    <div v-if="items.type == 3" >
                                        <div class="dragg" :style="`height:${items.height}px;background:${items.content.bgColor}`">
                                            <div v-for="(o, p) of items.content.imgs" :key="p">
                                                <div :style="`width:${items.content.width};height:${items.height}px;position: relative;`">
                                                    <img :style="`width:100%;height:100%;object-fit: cover;padding:${items.content.paddingData.top}px ${items.content.paddingData.leftRight}px ${items.content.paddingData.bottom}px ${items.content.paddingData.leftRight}px;object-fit: cover;border-radius:${items.content.paddingData.borderRadius}px`" :src="o.picview"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--items.type == 4 视频 -->
                                    <div v-if="items.type == 4">
                                        <div  class="dragg" :style="`height:${items.height}px;`">
                                            <div v-for="(o, p) of items.content.video" :key="p">
                                                <div :style="`width:${items.content.width};height:${items.height}px;position: relative;`">
                                                    <video :style="`width:100%;height:100%;object-fit: cover;padding:${items.content.paddingData.top}px ${items.content.paddingData.leftRight}px ${items.content.paddingData.bottom}px ${items.content.paddingData.leftRight}px;border-radius:${items.content.paddingData.borderRadius}px`" :src="o.picview"></video>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </transition-group>
                </draggable>
            </div>
        </div>
        <div class="right" v-if="!newFormData.content">
            <div class="title"></div>
            <div class="right-content"></div>
        </div>
        <!-- banner -->
        <div class="right" v-if="newFormData.content && newFormData.type == 1">
            <div class="title">
                <div class="yuan_FF761D DW_nei_txt">{{ newFormData.name }}</div>
            </div>
            <div class="right-content">
                <div class="head">
                    <Menu mode="horizontal" active-name="1" @on-select="selectHead">
                        <MenuItem name="1" class="head-text">内容设置</MenuItem>
                        <MenuItem name="2" class="head-text">边距设置</MenuItem>
                    </Menu>
                </div>
                <div class="content">
                    <div v-if="headID == 1">
                        <div style="display: flex;align-items: center;margin-bottom: 20px;">
                            <div class="content-text">图片高度</div>
                            <div style="display: flex">
                                <Input v-model="newFormData.height"></Input>
                                <div class="kuai_box">PX</div>
                            </div>
                        </div>
                        <div>
                            <div class="content-text">轮播图</div>
                            <div class="style_box" v-for="(item, index) of newFormData.content.imgList" :key="index">
                                <div class="banner_box">
                                    <img @click.stop="delBtnStyle(index)" class="del_icon_style" src="../../../assets/homeset/del.png"/>
                                    <Upload
                                    ref="upload"
                                    :show-upload-list="false"
                                    :format="['jpg', 'jpeg', 'png', 'gif']"
                                    :max-size="2048"
                                    :on-format-error="handleFormatError"
                                    :on-success="(res) => bannerSuccess(res, item)"
                                    :on-progress="handleProgress"
                                    :headers="headers"
                                    :action="uploadImg"
                                    >
                                        <Icon class="absolute_icon" type="md-add" />
                                    </Upload>
                                    <img :src="item.picview"/>
                                </div>
                            </div>
                            <div @click="addbanner" class="add_div"><img class="add_icon" src="../../../assets/homeset/plus.png"/>添加</div>
                        </div>
                    </div>
                    <div v-if="headID == 2">
                        <div class="content-text2">组件边距</div>
                        <div style="display: flex;align-items: center;margin-bottom: 20px;">
                            <div class="content-text">上边距</div>
                            <template><Slider v-model="newFormData.content.paddingData.top" style="width: 300px"></Slider></template>
                        </div>
                        <div style="display: flex;align-items: center;margin-bottom: 20px;">
                            <div class="content-text">下边距</div>
                            <Slider v-model="newFormData.content.paddingData.bottom" style="width: 300px"></Slider>
                        </div>
                        <div style="display: flex;align-items: center;margin-bottom: 20px;">
                            <div class="content-text">左右边距</div>
                            <Slider v-model="newFormData.content.paddingData.leftRight" style="width: 300px"></Slider>
                        </div>
                        <div class="content-text2">圆角设置</div>
                        <div style="display: flex;align-items: center;margin-bottom: 20px;">
                            <div class="content-text">圆角</div>
                            <Slider v-model="newFormData.content.paddingData.borderRadius" style="width: 300px"></Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- grid -->
        <div class="right" v-if="newFormData.content && newFormData.type == 2">
            <div class="title">
                <div class="yuan_FF761D DW_nei_txt">{{ newFormData.name }}</div>
            </div>
            <div class="right-content">
                <div class="content">
                    <div style="display: flex; align-items: center; margin-bottom: 20px">
                        <div class="content-text">文字颜色</div>
                        <ColorPicker v-model="newFormData.content.textColor" />
                    </div>
                    <div style="display: flex; align-items: center; margin-bottom: 20px">
                        <div class="content-text">背景颜色</div>
                        <ColorPicker v-model="newFormData.content.bgColor" />
                    </div>
                    <div>
                        <div class="content-text">图片高度</div>
                        <div style="width: 345px;margin-left: 126px;">
                            <div v-for="(item, index) of newFormData.content.iconList" :key="index" class="item_icon_box">
                                <img @click.stop="delIconBtn(index)" class="del_icon_menu" src="@/assets/homeset/del.png"/>
                                <div class="icon_menu_box">
                                    <img :src="item.picview" alt="" />
                                    <Upload
                                        ref="upload"
                                        :show-upload-list="false"
                                        :format="['jpg', 'jpeg', 'png']"
                                        :max-size="2048"
                                        :on-format-error="handleFormatError"
                                        :on-success="(res) => iconSuccess(res, item)"
                                        :on-progress="handleProgress"
                                        :headers="headers"
                                        :action="uploadImg"
                                    >
                                        <Icon class="absolute_icon" type="md-add" />
                                    </Upload>
                                </div>
                                <div>
                                    <div style="margin-bottom: 5px" class="just_sb">
                                        <div class="display_flex">
                                            <div class="kuai_box_grid">标题</div>
                                            <Input placeholder="标题" v-model="item.title" style="width: 110px"></Input>
                                        </div>
                                        <div class="display_flex">
                                            <div class="kuai_box_grid">跳转<br />链接</div>
                                            <Select style="width: 110px" v-model="item.id">
                                                <Option
                                                    v-for="item in pageList"
                                                    :value="item.id"
                                                    :key="item.id"
                                                >{{ item.title }}</Option
                                                >
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div @click="addIcon" class="add_icon_grid"><img class="add_icon" src="../../../assets/homeset/plus.png"/>添加</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 图片 -->
        <div class="right" v-if="newFormData.content && newFormData.type == 3">
            <div class="title">
                <div class="yuan_FF761D DW_nei_txt">{{ newFormData.name }}</div>
            </div>
            <div class="right-content">
                <div>
                    <div class="head">
                        <Menu mode="horizontal" active-name="1" @on-select="selectHead">
                            <MenuItem name="1" class="head-text">内容设置</MenuItem>
                            <MenuItem name="2" class="head-text">边距设置</MenuItem>
                        </Menu>
                    </div>
                    <div class="content">
                        <div v-if="headID == 1">
                            <div><div class="content-text2">图片上传</div></div>
                            <div style="display: flex;align-items: center;margin-bottom: 20px;">
                                <div class="content-text">图片高度</div>
                                <div style="display: flex">
                                    <Input v-model="newFormData.height"></Input>
                                    <div class="kuai_box">PX</div>
                                </div>
                            </div>
                            <div>
                                <div class="content-text">图片</div>
                                <div class="style_box" v-for="(item, index) of newFormData.content.imgs" :key="index">
                                    <div class="banner_box">
                                        <img @click.stop="delPictureStyle(index)" class="del_icon_style" src="../../../assets/homeset/del.png"/>
                                        <Upload
                                        ref="upload"
                                        :show-upload-list="false"
                                        :format="['jpg', 'jpeg', 'png', 'gif']"
                                        :max-size="2048"
                                        :on-format-error="handleFormatError"
                                        :on-success="(res) => pictureSuccess(res, item)"
                                        :on-progress="handleProgress"
                                        :headers="headers"
                                        :action="uploadImg"
                                        >
                                            <Icon class="absolute_icon" type="md-add" />
                                        </Upload>
                                        <img :src="item.picview"/>
                                    </div>
                                </div>
                            </div>
                            <div><div class="content-text2">背景设置</div></div>
                            <div style="display: flex;align-items: center;margin-bottom: 20px;">
                                <div class="content-text">背景颜色</div>
                                <div style="display: flex">
                                    <ColorPicker v-model="newFormData.content.bgColor" />
                                </div>
                            </div>
                        </div>
                        <div v-if="headID == 2">
                            <div class="content-text2">组件边距</div>
                            <div style="display: flex;align-items: center;margin-bottom: 20px;">
                                <div class="content-text">上边距</div>
                                <Slider v-model="newFormData.content.paddingData.top" style="width: 300px"></Slider>
                            </div>
                            <div style="display: flex;align-items: center;margin-bottom: 20px;">
                                <div class="content-text">下边距</div>
                                <Slider v-model="newFormData.content.paddingData.bottom" style="width: 300px"></Slider>
                            </div>
                            <div style="display: flex;align-items: center;margin-bottom: 20px;">
                                <div class="content-text">左右边距</div>
                                <Slider v-model="newFormData.content.paddingData.leftRight" style="width: 300px"></Slider>
                            </div>
                            <div class="content-text2">圆角设置</div>
                            <div style="display: flex;align-items: center;margin-bottom: 20px;">
                                <div class="content-text">圆角</div>
                                <Slider v-model="newFormData.content.paddingData.borderRadius" style="width: 300px"></Slider>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <div class="right" v-if="newFormData.content && newFormData.type == 4">
            <div class="title">
                <div class="yuan_FF761D DW_nei_txt">{{ newFormData.name }}</div>
            </div>
            <div class="right-content">
                <div>
                    <div class="head">
                        <Menu mode="horizontal" active-name="1" @on-select="selectHead">
                            <MenuItem name="1" class="head-text">内容设置</MenuItem>
                            <MenuItem name="2" class="head-text">边距设置</MenuItem>
                        </Menu>
                    </div>
                    <div class="content">
                        <div v-if="headID == 1">
                            <div><div class="content-text2">添加视频</div></div>
                            <div style="display: flex;align-items: center;margin-bottom: 20px;">
                                <div class="content-text">视频高度</div>
                                <div style="display: flex">
                                    <Input v-model="newFormData.height"></Input>
                                    <div class="kuai_box">PX</div>
                                </div>
                            </div>
                            <div>
                                <div class="content-text">视频添加</div>
                                <div class="style_box" v-for="(item, index) of newFormData.content.video" :key="index">
                                    <div class="banner_box">
                                        <img @click.stop="delVideoStyle(index)" class="del_icon_style" src="../../../assets/homeset/del.png"/>
                                        <Upload
                                        ref="upload"
                                        :show-upload-list="false"
                                        :format="['mp4', 'avi', 'mov']"
                                        :max-size="5000"
                                        :on-format-error="handleFormatError"
                                        :on-success="(res) => videoSuccess(res, item)"
                                        :on-progress="handleProgress"
                                        :headers="headers"
                                        :action="uploadImg"
                                        >
                                            <Icon class="absolute_icon" type="md-add" />
                                        </Upload>
                                        <video :src="item.picview"></video>
                                    </div>
                                    <div><div class="content-text2">播放设置</div></div>
                                </div>
                            </div>
                            <div><div class="content-text2">播放设置</div></div>
                            <div style="display: flex;align-items: center;margin-bottom: 20px;">
                                <div class="content-text">自动播放</div>
                                <div style="display: flex"><i-switch v-model="newFormData.autoplay" @on-change="changeAutoplay"></i-switch></div>
                            </div>
                            <div style="display: flex;align-items: center;margin-bottom: 20px;">
                                <div class="content-text">循环播放</div>
                                <div style="display: flex"><i-switch v-model="newFormData.loop" @on-change="changeLoop"></i-switch></div>
                            </div>
                        </div>
                        <div v-if="headID == 2">
                            <div class="content-text2">组件边距</div>
                            <div style="display: flex;align-items: center;margin-bottom: 20px;">
                                <div class="content-text">上边距</div>
                                <Slider v-model="newFormData.content.paddingData.top" style="width: 300px"></Slider>
                            </div>
                            <div style="display: flex;align-items: center;margin-bottom: 20px;">
                                <div class="content-text">下边距</div>
                                <Slider v-model="newFormData.content.paddingData.bottom" style="width: 300px"></Slider>
                            </div>
                            <div style="display: flex;align-items: center;margin-bottom: 20px;">
                                <div class="content-text">左右边距</div>
                                <Slider v-model="newFormData.content.paddingData.leftRight" style="width: 300px"></Slider>
                            </div>
                            <div class="content-text2">圆角设置</div>
                            <div style="display: flex;align-items: center;margin-bottom: 20px;">
                                <div class="content-text">圆角</div>
                                <Slider v-model="newFormData.content.paddingData.borderRadius" style="width: 300px"></Slider>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        </div>
    </div>
    <div class="foot">
        <div><Button type="primary" @click="save">保存</Button></div>
    </div>
  </div>
</template>

<script>
import { getCookies } from "@/utils/helper";
import config from "@/config/index";
import Swiper from "swiper";
import { otherDiy,addDiy,pageSelect} from "@/api/index";
import draggable from "vuedraggable";
export default {
    components: { draggable},
    data() {
        return {
            uploadImg: config.apiUrl + "/other/uploads",
            headers: {
                "authori-zation-huoyun": getCookies("authori-zation-huoyun"),
            },
            formData: [],
            active_id: "",
            newFormData: {},
            headID: "1",
            pageList:[],
            jkljklklkk:'',
        };
    },
    mounted(){
        this.otherDiy(),
        this.initSwiper(),
        this.pageSelect()
    },
    methods: {
        changeAutoplay(e){
            this.newFormData.autoplay = e
        },
        changeLoop(e){
            this.newFormData.Loop = e
        },
        // 页面列表下拉
        pageSelect(){
            pageSelect().then(res=>{
                this.pageList = res.data
            }).catch(err=>{
                this.$Message.error(err.msg)
            })
        },
        // 初始化轮播
        initSwiper() {
            var swiper = new Swiper(".swiper-container", {
                pagination: '.swiper-pagination', //分页器
                observer: true,
                observeParents: true,
                loop: true, //循环
                spaceBetween: 30, // swiper-slide 间的距离为30
                autoplay: 5000, //时长
                autoplayDisableOnInteraction: false, //点击不会取消自动
            });
        },
        // 延时初始化
        timeout() {
            setTimeout(() => {
                this.initSwiper();
            }, 500);
        },
        otherDiy(){
            otherDiy().then(res=>{
                this.formData = res.data.diy_store
            }).catch(err=>{
                this.$Message.error(err.msg)
            })
        },
        save() {
            console.log("保存按钮");
            addDiy({diy_store:this.formData}).then(res=>{
                console.log('addDiy',res)
                this.$Message.success(res.msg)
            }).catch(err=>{
                this.$Message.error(err.msg)
            })
        },
        // 左侧菜单点击
        menuClick(e) {
            switch (e) {
            case 1:
                this.formData.push({
                    type: 1,
                    name: "轮播广告",
                    height: 100,
                    content: {
                        paddingData: { top: 0, bottom: 0,leftRight: 0, borderRadius: 0 },
                        imgList: [
                            {value: "/img/diy/banner.png",picview: "http://canyin.hulianzaowu.top/img/diy/banner.png",},
                        ],
                    },
                });
                break;
            case 2:
                this.formData.push({
                    type: 2,
                    name: "宫格导航",
                    content: {
                        bgColor: "#FFFFFF",
                        width: 50,
                        height: 50,
                        size: "14",
                        textColor: "#333333",
                        iconList: [
                            {value: "/img/diy/smail.png",picview: "http://canyin.hulianzaowu.top/img/diy/smail.png",title: "标题","id": 1,},
                            {value: "/img/diy/smail.png",picview: "http://canyin.hulianzaowu.top/img/diy/smail.png",title: "标题","id": 2,},
                            {value: "/img/diy/smail.png",picview: "http://canyin.hulianzaowu.top/img/diy/smail.png",title: "标题","id": 3,},
                        ],
                    },
                });
                break;
            case 3:
                this.formData.push({
                    type: 3,
                    name: "图片广告",
                    height: "80",
                    content: {
                        imgs: [{value: "/img/diy/goods.png",picview: "http://canyin.hulianzaowu.top/img/diy/goods.png",},],
                        bgColor: "#fff",
                        width: "100%",
                        paddingData: { top: 0, leftRight: 0, bottom: 0, borderRadius: 0},
                    },
                });
                break;
            case 4:
                this.formData.push({
                    type: 4,
                    name: "视频广告",
                    height: "120",
                    autoplay:"false",
                    loop:"false",
                    content: {
                        video: [{value: "/img/diy/goods.png",picview: "https://qiniu-web-assets.dcloud.net.cn/unidoc/zh/2minute-demo.mp4",},],
                        width: "100%",
                        paddingData: { top: 0, leftRight: 0, bottom: 0, borderRadius: 0},
                    },
                });
                break;
            default:
                console.log("组件组件组件");
                break;
            }
        },
        clickActive(e, index) {
            console.log("点击事件", index);
            this.active_id = index;
            this.newFormData = e;
        },
        // 删除
        delBtn(e) {
            this.formData.splice(e, 1);
            this.newFormData = '';
            this.active_id = '';
        },
        selectHead(name) {
            this.headID = name;
        },
        // 添加轮播图
        addbanner() {
            this.newFormData.content.imgList.push({
                picview: "http://canyin.hulianzaowu.top/img/diy/banner.png",
                value: "/img/diy/banner.png",
                action: 1,
                path: null,
                shop: null,
            });
        },
        // 删除轮播
        delBtnStyle(e) {
            if (this.newFormData.content.imgList.length == 1) {
                this.$Message.info("至少保留一张");
            } else {
                this.newFormData.content.imgList.splice(e, 1);
            }
        },
        // 删除icon列表
        delIconBtn(e) {
            if (this.newFormData.content.iconList.length == 1) {
                this.$Message.info("至少保留一个");
            } else {
                this.newFormData.content.iconList.splice(e, 1);
            }
        },
        // 添加icon列表
        addIcon() {
        this.newFormData.content.iconList.push({
            value: "/img/diy/smail.png",
            picview: "http://canyin.hulianzaowu.top/img/diy/smail.png",
            title: "标题",
            action: 1,
            path: null,
            shop: null,
            explain: "",
        });
        },
        // 删除图片
        delPictureStyle(e) {
            if (this.newFormData.content.imgs.length == 1) {
                this.$Message.info("至少保留一个");
            } else {
                this.newFormData.content.imgs.splice(e, 1);
            }
        },
        // 删除视频
        delVideoStyle(e) {
            if (this.newFormData.content.video.length == 1) {
                this.$Message.info("至少保留一个");
            } else {
                this.newFormData.content.video.splice(e, 1);
            }
        },
        //文件上传类型错误
        handleFormatError() {
            this.$Message.warning("暂不支持上传此格式");
        },
        // 文件上传时
        handleProgress() {
            const msg = this.$Message.loading({
                content: "上传中...",
                duration: 0,
            });
            setTimeout(msg, 3000);
        },
        // 轮播图上传
        bannerSuccess(e,item) {
            item.picview = e.data.path;
            this.$Message.destroy();
        },
        // 图标列表上传
        iconSuccess(e,item) {
            item.picview = e.data.path;
            this.$Message.destroy();
        },
        // 图片广告上传
        pictureSuccess(e,item) {
            console.log("tp上传", e,item);
            item.picview = e.data.path;
            this.$Message.destroy();
        },
        // 视频上传
        videoSuccess(e,item) {
            console.log("视频上传", e,item);
            item.picview = e.data.path;
            this.$Message.destroy();
        },
        // 移动时
        onStart(e) {
            this.drag = true;
            console.log("触发移动",e)
            this.active_id = e.oldDraggableIndex;
        },
        // 移动结束
        onEnd(e) {
            this.drag = false;
            this.active_id = e.newDraggableIndex;
            this.newFormData = this.formData[this.active_id]
        },
    },
};
</script>

<style lang="scss" scoped>
.swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 100%;
    height: 150px;
}
.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
}
.swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
}
.chosen {
    border: dashed 2px #ff761d !important;
}
.page {
    background-color: #F5F9FA;
}
.content {
    width: 100%;
    padding: 0 20px;
    .left {
        width: 400px;
        height: 77vh;
        overflow: auto;
        background-color: #fff;
        padding: 20px;
        .left-text {
            width: 100px;
            height: 24px;
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
        }
        .menu_box {
            width: 150px;
            height: 42px;
            background: #ffffff;
            border: 1px solid #dce5eb;
            border-radius: 2px;
            display: flex;
            margin: 20px;
            align-items: center;
            padding-left: 10px;
            cursor: pointer;
        }
    }
    .between {
        width: 630px;
        height: 77vh;
        overflow: hidden;
        background-color: #F5F9FA;
        display: flex;
        justify-content: center;
        .app {
            width: 345px;
            height: 100%;
            // overflow: hidden;
            background-color: #fff;
            overflow: auto;
        }
        .grid-box {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .items-box {
            width: 33.33%;
            text-align: center;
            margin-bottom: 5px;
            }
        }
        .items-active {
            border: solid 2px #3995fe !important;
            box-shadow: 5px 5px 3px 4px rgba(0, 0, 0, 0.4);
            position: relative;
            z-index: 999;
            cursor: pointer;
        }
        .items:hover {
            border: dashed 2px #3995fe !important;
            cursor: pointer;
        }
        .items-active .del_icon {
            opacity: 1;
        }
    }
    .right {
        width: 690px;
        height: 76vh;
        .title {
            padding-left: 20px;
            width: 100%;
            height: 10%;
            background-color: #fff;
            box-sizing: border-box;
            margin-bottom: 1vh;
            display: flex;
            align-items: center;
        }
        .right-content {
            height: 90%;
            width: 100%;
            background-color: #fff;
            overflow: auto;
            .head {
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 1px solid #ccc;
                .head-text {
                    font-size: 16px;
                    font-weight: 400;
                    margin-right: 20px;
                }
            }
            .content {
                padding: 20px;
                .content-text {
                    width: 78px;
                    height: 20px;
                    line-height: 20px;
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;
                    margin-right: 50px;
                }
                .content-text2 {
                    width: 78px;
                    height: 20px;
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    margin-bottom: 10px;
                }
                .kuai_box {
                    width: 30px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    border-radius: 0 5px 5px 0;
                    border: 1px solid #bfbfbf;
                }
                .style_box {
                    padding: 0 10px;
                    margin-left: 120px;
                }
                .add_div {
                    cursor: pointer;
                    height: 40px;
                    line-height: 40px;
                    margin-left: 126px;
                    color: #3995fe;
                }
                .add_icon {
                    width: 12px;
                    margin-right: 5px;
                }
                .banner_box {
                    width: 100%;
                    height: 130px;
                    display: flex;
                    margin: 10px 0;
                    justify-content: center;
                    position: relative;
                }
                .banner_box img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .absolute_icon {
                    position: absolute;
                    font-size: 50px;
                    cursor: pointer;
                    color: #fff;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 99999;
                }
                .del_icon_style {
                    position: absolute;
                    top: 0;
                    right: 10px;
                    z-index: 999;
                    width: 26px !important;
                    height: 26px !important;
                    cursor: pointer;
                }
                //宫格
                .item_icon_box {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 20px;
                    border: 1px solid #eee;
                    position: relative;
                    margin: 10px 0;
                }
                .icon_menu_box {
                    width: 80px;
                    height: 80px;
                    position: relative;
                    border-radius: 5px;
                    overflow: hidden;
                }
                .icon_menu_box img {
                    width: 100%;
                    height: 100%;
                }
                .del_icon_menu {
                    width: 20px !important;
                    height: 20px !important;
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    right: 0;
                }
                .just_sb {
                    .display_flex {
                        display: flex;
                        .kuai_box_grid {
                            width: 30px;
                            height: 32px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 12px;
                            border: 1px solid #bfbfbf;
                        }
                    }
                }
                .add_icon_grid{
                    height: 40px;
                    line-height: 40px;
                    margin-left: 126px;
                    color: #3995fe;
                    cursor: pointer;
                }
            }
        }
    }
}
.foot {
  width: 100%;
  background-color: #fff;
  padding: 10px 0 0 0;
  div {
    text-align: center;
  }
}
.yuan_FF761D::before {
  content: " ";
  display: block;
  width: 2px;
  height: 16px;
  margin-right: 10px;
  background: #3995fe;
}
.DW_nei_txt {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
}
.del_icon {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 999;
  width: 26px;
  height: 26px;
  cursor: pointer;
}
</style>